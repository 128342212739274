<script>
/**
 * Building an Autocomplete Component with Vue.js
 * https://alligator.io/vuejs/vue-autocomplete-component/
 */

const axios = require('axios');
const qs = require('qs');

const CancelToken = axios.CancelToken;

const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

export default {
    name: 'client_recherche',
    props: {
        // client sélectionnée par défaut
        // pour les form autocomplete
        // rensigné avec 'v-model'
        value: {default: null},
        // Pour les options de recherche
        form: {
            type: Object,
            default: () => {return {};}
            },
    },
    data() {
        return {
            search: '',
            placeholder: '',

            // Pour annuler les requêtes en cours
            // https://stackoverflow.com/questions/38329209/how-to-cancel-abort-ajax-request-in-axios
            cancel: null,

            isOpen: false,
            isLoading: false,
            timer: null,
            arrowCounter: -1,

            client: null,
            clients: [],
        };
    },
    created() {
        this.$eventHub.$on('client_recherche_clear_placeholder', () => {this.placeholder = ''});
    },
    beforeDestroy() {
        this.$eventHub.$off('client_recherche_clear_placeholder');
    },
    mounted() {
        document.addEventListener('click', this.onClickOutside);
        if (this.value && this.value.id) {
            this.placeholder =
                this.value.contact.civilite_nom_complet
                + " | " +
                this.value.contact.ville_dpt
            ;
        }
    },
    destroyed() {
        document.removeEventListener('click', this.onClickOutside);
    },
    methods: {
        onChange() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.searchResult, 300);
        },
        onEnter() {
            if (this.arrowCounter < 0) {
                // Rien
            }
            else {
                this.setResult(this.arrowCounter);
            }
        },
        onArrowDown() {
            if (this.arrowCounter < this.clients.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEscape() {
            this.search = "";
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        onClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.search = "";
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        searchResult() {
            // Annulation de la requête en cours
            if (this.cancel !== null && this.cancel !== undefined) {
                //console.log("cancel");
                this.cancel();
            }

            if (this.search.length > 3) {
                this.isOpen = true;
                this.isLoading = true;
                this.clients = [];

                var form_data = this.form;
                form_data.search = this.search;
                form_data.limit = 15;     // limite à 15 résultats
                var url = Routing.generate('api_client_search');
                var that = this;
                var cancelToken = {cancelToken: new CancelToken(function executor(c) {that.cancel = c;})};

                axios.post(url, qs.stringify(form_data), cancelToken)
                    .then((response) => {
                        this.isLoading = false;
                        this.clients = response.data.clients;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    })
                ;
            }
            else {
                this.isLoading = false;
                this.clients = [];
                this.isOpen = false;
            }
        },

        setResult(index) {
            this.isOpen = false;
            this.search = "";
            var client = this.clients[index];
            this.$emit('input', this.clients[index]);
            this.placeholder =
                client.contact.nom
                + " | " +
                client.contact.ville_dpt
            ;
        },
    }
};
</script>

