<script>
const axios = require('axios');
const qs = require('qs');
const moment = require('moment');

// import Editor from '@tinymce/tinymce-vue';
import VueTagsInput from '@johmun/vue-tags-input';
import Autocomplete from './autocomplete.vue';

const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

export default {
    name: 'affaire_popin',
    components: {
        // 'wysiwyg': Editor,
        'autocomplete': Autocomplete,
        VueTagsInput,
    },
    data() {
        return {
            affaire: null,
            tab: null,

            isEdit: false,

            focusLigne: null,

            // Pour l'ajout d'un commentaire
            commentaire: null,

            // Résumé des heures (onglet Heures)
            heures: [],
            total_heures: 0,
            total_paniers: 0,

            // Pour l'ajout d'une intervention
            intervention: {},

            // Pour l'édition d'un contact
            contact: {},

            // Pour l'édition des commentaires des lignes d'achat
            ligne_achat: {},

            // Pour l'ajout d'un jalon
            jalon: {
                id: null,
                libelle: '',
                date_jalon: null,
            },

            chefs_projet: [],
            agences: [],
            fournisseurs: [],
            poseurs: [],

            isLoading: false,

            error: null, // erreur générale
            errors: [],  // pour les formulaires

            tag: '',

            // wysiwyg_config: {
            //     menubar: false,
            //     plugins: 'lists paste',
            //     paste_as_text: true,
            //     toolbar: 'undo redo | bold italic underline | numlist bullist | removeformat',
            // },

        };
    },
    computed: {
        filtred_poseurs() {
            return this.poseurs.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },
        total_estimation_achat: function() {
            var t = 0;
            this.affaire.lignes_achat.forEach(function(l) {
                t += !l.fin_chantier ? parseFloat(l.montant_estimation)||0 : 0;
            });
            return t;
        },
        total_confirmation_achat: function() {
            var t = 0;
            this.affaire.lignes_achat.forEach(function(l) {
                t += !l.fin_chantier ? parseFloat(l.montant_confirmation)||0 : 0;
            });
            return t;
        },
        total_estimation_fin_chantier: function() {
            var t = 0;
            this.affaire.lignes_achat.forEach(function(l) {
                t += l.fin_chantier ? parseFloat(l.montant_estimation)||0 : 0;
            });
            return t;
        },
        total_confirmation_fin_chantier: function() {
            var t = 0;
            this.affaire.lignes_achat.forEach(function(l) {
                t += l.fin_chantier ? parseFloat(l.montant_confirmation)||0 : 0;
            });
            return t;
        },
        /**
         * Somme des quantités des lignes d'achats
         */
        total_achats_quantite: function() {
            var t = 0;
            this.affaire.lignes_achat.forEach(function(l) {
                t += parseInt(l.quantite) || 0;
            });
            return t;
        },
        /**
         * Somme HT des montants des situations
         */
        total_situations_ht: function() {
            var t = 0;
            this.affaire.situations.forEach(function(l) {
                t += parseFloat(l.montant_ht) || 0;
            });
            return t;
        },
        /**
         * Somme TVA des montants des situations
         */
        total_situations_tva: function() {
            var t = 0;
            this.affaire.situations.forEach(function(l) {
                t += parseFloat(l.montant_tva) || 0;
            });
            return t;
        },
        /**
         * Somme TTC des montants des situations
         */
        total_situations_ttc: function() {
            var t = 0;
            this.affaire.situations.forEach(function(l) {
                t += parseFloat(l.montant_ht) || 0;
                t += parseFloat(l.montant_tva) || 0;
            });
            return t;
        },
        /**
         * Somme des règlements des situations
         */
        total_situations_reglements: function() {
            var t = 0;
            this.affaire.situations.forEach(function(l) {
                t += parseFloat(l.montant_reglement) || 0;
            });
            return t;
        },

        form_intervention_valid: function() {
            return (
                null !== this.intervention.date_debut && "" !== this.intervention.date_debut
             && null !== this.intervention.date_fin && "" !== this.intervention.date_fin
             && null !== this.intervention.heure_debut && "" !== this.intervention.heure_debut
             && null !== this.intervention.heure_fin && "" !== this.intervention.heure_fin
             && null !== this.intervention.is_option
             && Array.isArray(this.intervention.poseurs) && this.intervention.poseurs.length > 0
            );
        },

        route_planning: function() {
            switch (this.affaire.type) {
                case 'particulier':
                    return Routing.generate('planning_projet_particuliers');
                case 'chantier':
                    return Routing.generate('planning_projet_chantiers');
                case 'sav':
                    return Routing.generate('planning_projet_sav');
            }
        },

        route_print_control: function() {
            return Routing.generate('print_control', {id: this.affaire.id});
        },
        route_print_intervention_sav: function() {
            return Routing.generate('print_intervention_sav', {id: this.affaire.id});
        },
    },
    watch: {
        'contact.adresse': function(val) {
            if (this.isEdit === 'contact') this.contact.zone = "";
        },
        'contact.ville': function(val) {
            if (this.isEdit === 'contact') this.contact.zone = "";
        },
        'contact.code_postal': function(val) {
            if (this.isEdit === 'contact') this.contact.zone = "";
        },
        'isLoading': function(val) {
            this.$eventHub.$emit('loading', val);
        },
    },
    created() {
        /**
         * Demande d'affichage d'une affaire
         */
        this.$eventHub.$on('affaire', this.afficherAffaire);
        /**
         * Envoi des données de préremplissage
         * du formulaire d'ajout d'une intervention
         */
        this.$eventHub.$on('planning', this.formIntervention);
        /**
         * Rechargement de l'affaire
         */
        this.$eventHub.$on('reload', this.reload);
    },
    mounted() {
    },
    beforeDestroy() {
        this.$eventHub.$off('affaire');
        this.$eventHub.$off('planning');
        this.$eventHub.$off('reload');
        document.removeEventListener('keydown', this.doKeyEvt);
    },
    destroyed() {
    },
    methods: {

        /**
         * Gestion des raccourcis clavier
         */
        doKeyEvt(e) {
            //console.log(e.key, this.isEdit);
            switch (e.key) {
                case "Enter":
                    //if (this.isEdit === 'ligne' && this.focusLigne) {
                    //    this.save_ligne_achat(this.focusLigne);
                    //}
                    break;
                case "Escape":
                    if (this.isEdit === false) {
                        this.close();
                    }
                    //else if (this.isEdit === 'ligne' && this.focusLigne) {
                    //    this.edit_ligne_achat(this.focusLigne, false);
                    //}
                    break;
            }
        },

        initPopin() {
            this.isEdit = false;

            this.commentaire = null;
            this.error = null;
            this.errors = [];
        },

        /**
         * Affichage de l'affaire demandée
         * tab = onglet, 'aperçu' par défaut
         */
        afficherAffaire(id, tab = 'apercu') {
            //console.log("afficherAffaire", id, tab);
            this.isLoading = true;
            this.initPopin();
            var url = Routing.generate('api_affaire_get', {id: id});
            axios.get(url)
                .then((response) => {
                    this.affaire = response.data.affaire;
                    this.chefs_projet = response.data.chefs_projet;
                    this.agences = response.data.agences;
                    this.fournisseurs = response.data.fournisseurs;
                    this.poseurs = response.data.poseurs;

                    this.heures = response.data.heures;
                    this.total_heures = response.data.total_heures;
                    this.total_paniers = response.data.total_paniers;

                    this.intervention = response.data.intervention;
                    this.tab = tab;

                    /** Gestion des raccourcis clavier */
                    document.addEventListener('keydown', this.doKeyEvt);

                    // Fin de chargement
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Recharge l'affaire courante sur l'onglet courant
         */
        reload() {
            this.afficherAffaire(this.affaire.id, this.tab);
        },

        /**
         * Gestion des retour d'erreur de l'API
         */
        error_api(error) {
            this.isEdit = false;
            this.isLoading = false;
            //console.log(error, error.response);
            if (error.response && error.response.status === 403) {
                this.$eventHub.$emit('flash-error', "Droits insuffisants");
            }
            else if (error.response) {
                this.errors = error.response.data ?? [];
            }
            else {
                this.$eventHub.$emit('flash-error', error);
            }
        },
        
        /**
         * Fermeture de la popin
         */
        close() {
            if (this.isEdit === false) {
                // ON ferme si pas en édition
                this.isEdit = false;
                this.affaire = null;
                this.$eventHub.$emit('affaire_closed');
                document.removeEventListener('keydown', this.doKeyEvt);
            }
        },

        /**
         * Annule l'édition de l'affaire,
         * Recharge l'affaire
         */
        cancel_edit() {
            //console.log("cancel_edit");
            this.initPopin();
            this.afficherAffaire(this.affaire.id, this.tab);
        },

        /**
         * Enregistre les modifications générales de l'affaire
         */
        save_affaire() {
            //console.log("save_affaire");
            this.errors = [];

            var url = Routing.generate('api_affaire_save', {id: this.affaire.id});
            var data = qs.stringify(this.affaire, {filter: function(prefix, value) {
                switch (prefix) {
                    case 'chef_projet':
                        return value ? value.id : null;
                    case '': // Pour le préfix racine
                    case 'nom_chantier':
                    case 'description':
                    case 'motif_fin_chantier':
                    case 'numero_facture':
                    case 'date_reception':
                    case 'champ_libre1':
                    case 'champ_libre2':
                    case 'champ_libre3':
                        return value;
                }
            }});
            axios.patch(url, data)
                .then((response) => {
                    this.isEdit = false;
                    this.$eventHub.$emit('flash-success', "Affaire enregistrée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Change le type d'une affaire
         */
        switch_affaire(new_type) {
            this.errors = [];

            //var new_type = this.affaire.type === 'chantier' ? 'particulier' : 'chantier';
            var url = Routing.generate('api_affaire_switch', {id: this.affaire.id});
            var data = qs.stringify({'type': new_type});
            axios.post(url, data)
                .then((response) => {
                    this.initPopin();
                    this.afficherAffaire(this.affaire.id);
                    this.$eventHub.$emit('flash-success', "Affaire convertie en " + new_type);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;

        },

        /**
         * Enregistre le numéro de facture
         */
        save_facture() {
            this.errors = [];

            var url = Routing.generate('api_affaire_numero_facture', {id: this.affaire.id});
            var data = qs.stringify({'numero_facture': this.affaire.numero_facture});
            axios.patch(url, data)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isEdit = false;
                    this.$eventHub.$emit('flash-success', "Numéro facture enregistré");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Enregistre la date de réception
         */
        save_date_reception() {
            this.errors = [];

            var url = Routing.generate('api_affaire_date_reception', {id: this.affaire.id});
            var data = qs.stringify({'date_reception': this.affaire.date_reception});
            axios.patch(url, data)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isEdit = false;
                    this.$eventHub.$emit('flash-success', "Date de réception enregistrée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Change le statut de l'affaire
         */
        to_statut(statut) {
            //console.log("to_statut", statut);

            let motif = null;
            let restant_du = null;
            if (statut === "fin_chantier") {
                motif = window.prompt("Motif de fin de chantier");
                if (motif === null) return;
                restant_du = window.prompt("Restant dû");
                if (restant_du === null) return;
            }

            this.errors = [];
            this.isLoading = true;
            
            var url = Routing.generate('api_affaire_statut', {id: this.affaire.id});
            var data = qs.stringify({statut: statut, motif: motif, restant_du: restant_du});
            axios.post(url, data)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isEdit = false;
                    this.isLoading = false;
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Enregistre les infos synthèse (données Prodevis)
         */
        save_synthese() {
            //console.log("save_synthese");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_synthese_save', {id: this.affaire.id});
            var data = qs.stringify(this.affaire.synthese);
            axios.post(url, data)
                .then((response) => {
                    //console.log(response);
                    this.isEdit = false;
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Affaire enregistrée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Enregistre un contact (adresse chantier ou facturation)
         */
        save_contact() {
            //console.log("save_contact");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_contact_save', {id: this.affaire.id});
            var data = qs.stringify(this.contact);
            axios.patch(url, data)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.contact = {};
                    this.$eventHub.$emit('flash-success', "Contact enregistré");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        delete_contact(contact) {
            //console.log("delete_contact_achat");

            // Confirmation pour supprimer
            if (!confirm("Supprimer ?")) {
                return;
            }

            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_contact_delete', {id: this.affaire.id});
            var data = qs.stringify({id: contact.id});
            axios.post(url, data)
                .then((response) => {
                    this.initPopin();
                    // On recharge l'affaire
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "contact supprimée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Calcule de la zone
         */
        zone_contat(contact) {
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_contact_zone', {id: this.affaire.id});
            var data = qs.stringify({id: contact.id});
            axios.patch(url, data)
                .then((response) => {
                    this.initPopin();
                    // On recharge l'affaire
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Zone géolocalisée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Evenement d'ajout d'une intervention depuis le planning
         * Prérempli le formulaire avec les données récupérées du planning
         */
        formIntervention(form) {
            // date du formulaire au format standard
            var formdate = moment(String(form.date)).format('YYYY-MM-DD');
            this.intervention.date_debut = formdate;
            this.intervention.date_fin = formdate;
            //console.log(form, formdate, this.intervention);

            if (form.heure_debut) {
                this.intervention.heure_debut = form.heure_debut;
            }
            if (form.heure_fin) {
                this.intervention.heure_fin = form.heure_fin;
            }

            this.poseurs.forEach((p) => {
                if (form.poseurs.indexOf(p.id) >= 0) {
                    this.intervention.poseurs.push(p);
                }
            });
        },

        /**
         * Enregistre une intervention
         */
        save_intervention() {

            // Pas si le formulaire est invalide
            if (!this.form_intervention_valid) return;

            //console.log("save_intervention");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_intervention', {id: this.affaire.id});
            var data = qs.stringify(this.intervention, {filter: function(prefix, value) {
                    switch (prefix) {
                        case 'poseur':
                            return;
                        case 'poseurs':
                            // On retourne la liste des IDs des poseurs
                            if (Array.isArray(value)) {
                                return value.map(function(p) { return p.id; });
                            }
                            break;
                        case '': // Pour le préfix racine
                        default:
                            return value;
                    }
                }});
            axios.post(url, data)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isEdit = false;
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Intervention enregistrée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                    
                    if (this.affaire.warning.warning_pose_depassee || this.affaire.warning.alerte_pose_depassee) {
                        this.$eventHub.$emit('flash-warning', "Pose vendue dépassée");
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        edit_intervention(intervention) {
            this.edit = 'intervention';
            this.intervention = intervention;
        },
        
        delete_intervention(intervention) {
            //console.log("delete_intervention");

            // Confirmation pour annuler un élément
            if (!confirm("Supprimer l'intervention ?")) return;

            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_intervention_delete', {id: this.affaire.id});
            var data = qs.stringify(intervention, {filter: function(prefix, value) {
                    switch (prefix) {
                        case 'poseurs':
                            break;
                        case '': // Pour le préfix racine
                        default:
                            return value;
                    }
                }});
            axios.post(url, data)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isEdit = false;
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Intervention supprimée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },
        
        cancel_intervention() {
            this.isEdit = false;
            this.afficherAffaire(this.affaire.id, this.tab);
        },

        /**
         * RG_AF016b
         * Gestion des jalons
         * @param jalon
         * @param check
         */
        check_jalon(jalon, check = true) {

            // Confirmation pour annuler un élément
            if (!check && !confirm("Annuler ?")) return;

            this.error = null;
            this.isLoading = true;

            var url = Routing.generate('api_check_jalon', {id: jalon.id, check: check ? '1' : 0});
            axios.post(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * @param jalon
         * @param nc
         */
        nc_jalon(jalon, nc = true) {

            // Confirmation pour annuler un élément
            if (!nc && !confirm("Annuler ?")) return;

            this.error = null;
            this.isLoading = true;

            var url = Routing.generate('api_nc_jalon', {id: jalon.id, check: nc ? '1' : 0});
            axios.post(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },



        /**
         * Enregistre un jalon
         */
        add_jalon() {
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_jalon', {id: this.affaire.id});
            var data = qs.stringify(this.jalon);

            axios.post(url, data)
                .then((response) => {
                    this.isLoading = false;
                    this.isEdit = false;
                    // Reset jalon
                    this.jalon = {libelle: '', date_jalon: null};
                    this.afficherAffaire(this.affaire.id, this.tab);
                    this.$eventHub.$emit('flash-success', "Jalon enregistré");
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Supprime un jalon
         */
        delete_jalon() {
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_jalon_delete', {id: this.affaire.id});
            var data = qs.stringify(this.jalon);

            axios.post(url, data)
                .then((response) => {
                    this.isLoading = false;
                    this.isEdit = false;
                    // Reset jalon
                    this.jalon = {id: null, libelle: '', date_jalon: null};
                    this.afficherAffaire(this.affaire.id, this.tab);
                    this.$eventHub.$emit('flash-success', "Jalon supprimé");
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Validation comptable des lignes d'achat
         */
        validation_ligne_achat(ligne, statut = true) {

            // Non validable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'ligne') {
                return;
            }

            // Confirmation pour dévalider une ligne d'achat
            if (!statut && !confirm("Annuler ?")) {
                return;
            }

            // RG_AF033: Validation seulement si numéro de facture renseigné
            if (statut && ligne.numero_facture == null) {
                //alert("Ligne d'achat sans numéro de facture");
                this.$eventHub.$emit('flash-error', "Ligne d'achat sans numéro de facture");
                return;
            }

            this.error = null;
            this.isLoading = true;

            var url = Routing.generate('api_ligne_achat_statut', {id: ligne.id, statut: statut ? '1' : 0});
            axios.post(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Ligne d'achat " + (statut ? "validée" : "invalidée"));
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Passe la ligne d'achat en mode édition
         * @param ligne
         * @param isEdit
         */
        edit_ligne_achat(ligne, isEdit) {

            /*
            // RG_AF033: ligne éditable tant qu'elle n'est pas validée
            if (ligne.statut) {
                //alert("Impossible d'éditer une ligne d'achat validée");
                that.$eventHub.$emit('flash-error', "Impossible d'éditer une ligne d'achat validée");
                return;
            }
            */

            // Non éditable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'ligne') {
                return;
            }

            ligne.is_edit = isEdit;

            if (isEdit) {
                this.isEdit = 'ligne';
                this.focusLigne = ligne;
                this.errors = [];
                // Edition, on force l'actualisation, pour afficher le formulaire
                // Je sais pas pourquoi vue ne détecte pas le changement de ligne.is_edit
                this.$forceUpdate();
            }
            else {
                this.focusLigne = null;
                // Annulation, on recharge l'affaire pour réinitialiser les données éventuellement modifiées
                this.initPopin();
                this.afficherAffaire(this.affaire.id, this.tab);
                
            }
        },

        /**
         * Enregistre une ligne d'achat
         */
        save_ligne_achat(ligne) {
            //console.log("save_ligne_achat");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_ligne_achat_save', {id: this.affaire.id});
            var data = qs.stringify(ligne);

            //console.log(this.is_edit_achat());

            axios.post(url, data)
                .then((response) => {
                    ligne.is_edit = false;
                    this.focusLigne = null;
                    this.isLoading = false;
                    this.ligne_achat = {};
                    this.$eventHub.$emit('flash-success', "Ligne d'achat enregistrée");

                    // Si plus aucune ligne d'achat en édition
                    if (!this.is_edit_achat()) {
                        this.initPopin();
                    }
                    else {
                        // Edition, on force l'actualisation, pour afficher le formulaire
                        // Je sais pas pourquoi vue ne détecte pas le changement de ligne.is_edit
                        this.$forceUpdate();
                    }
                    
                    // On recharge l'affaire
                    this.afficherAffaire(this.affaire.id, this.tab);
                    
                    console.log(this.affaire.warning);
                    if (this.affaire.warning.warning_achat_depasse || this.affaire.warning.alerte_achat_depasse) {
                        this.$eventHub.$emit('flash-warning', "Attention, l'achat est dépassé");
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Retourne VRAI si au moins une ligne d'achat est en édition
         * @returns {boolean}
         */
        is_edit_achat: function() {
            var is_edit = false;
            this.affaire.lignes_achat.forEach(function(l) {
                is_edit = is_edit || l.is_edit;
            });
            return is_edit;
        },

        delete_ligne_achat(ligne) {
            //console.log("delete_ligne_achat");
            // Confirmation pour supprimer
            if (!confirm("Supprimer la ligne d'achat ?")) {
                return;
            }

            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_ligne_achat_delete', {id: this.affaire.id});
            var data = qs.stringify({
                id: ligne.id,
            });

            axios.post(url, data)
                .then((response) => {
                    this.initPopin();
                    // On recharge l'affaire
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Ligne d'achat supprimée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        ligne_achat_new(fin_chantier = false) {
            //console.log("save_ligne_achat");
            this.isLoading = true;

            var url = Routing.generate('api_ligne_achat_new', {id: this.affaire.id});
            axios.get(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    // Nouvelle ligne
                    let ligne = response.data.ligne;
                    ligne.fin_chantier = fin_chantier;
                    // On l'ajoute à l'affaire
                    this.affaire.lignes_achat.push(ligne);
                    // et on l'édite
                    this.edit_ligne_achat(ligne, true);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Validation comptable des avoirs
         */
        validation_avoir(avoir, statut = true) {
            //console.log("validation_avoir", avoir.id, statut);

            // Non validable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'avoir') {
                return;
            }

            // Confirmation pour dévalider un avoir
            if (!statut && !confirm("Annuler ?")) {
                return;
            }

            this.error = null;
            this.isLoading = true;

            var url = Routing.generate('api_avoir_statut', {id: avoir.id, statut: statut ? '1' : 0});
            axios.post(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Avoir " + (statut ? "validé" : "invalidé"));
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Passe l'avoir en mode édition
         * @param avoir
         * @param isEdit
         */
        edit_avoir(avoir, isEdit) {
            // RG_AF033: avoir éditable tant qu'elle n'est pas validée
            if (avoir.statut) {
                this.$eventHub.$emit('flash-error', "Impossible d'éditer un avoir validé");
                return;
            }

            // Non éditable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'avoir') {
                return;
            }

            avoir.is_edit = isEdit;

            if (isEdit) {
                this.isEdit = 'avoir';
                this.errors = [];
                // Edition, on force l'actualisation, pour afficher le formulaire
                // Je sais pas pourquoi vue ne détecte pas le changement de avoir.isEdit
                this.$forceUpdate();
            }
            else {
                // Annulation, on recharge l'affaire pour réinitialiser les données éventuellement modifiées
                this.initPopin();
                this.afficherAffaire(this.affaire.id, this.tab);
            }
        },

        /**
         * Enregistre un avoir
         */
        save_avoir(avoir) {
            //console.log("save_avoir");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_avoir_save', {id: this.affaire.id});
            var data = qs.stringify(avoir);

            axios.post(url, data)
                .then((response) => {
                    avoir.is_edit = false;
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Avoir enregistré");

                    // Si plus aucun avoir en édition
                    if (!this.is_edit_avoir()) {
                        this.initPopin();
                        // On recharge l'affaire
                        this.afficherAffaire(this.affaire.id, this.tab);
                    }
                    else {
                        // Edition, on force l'actualisation, pour afficher le formulaire
                        // Je sais pas pourquoi vue ne détecte pas le changement de avoir.isEdit
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Retourne VRAI si au moins un avoir est en édition
         * @returns {boolean}
         */
        is_edit_avoir: function() {
            var is_edit = false;
            this.affaire.avoirs.forEach(function(a) {
                is_edit = is_edit || a.is_edit;
            });
            return is_edit;
        },

        /**
         * Supprime un avoir
         */
        delete_avoir(avoir) {
            //console.log("delete_avoir");

            // Confirmation pour supprimer
            if (!confirm("Supprimer ?")) {
                return;
            }

            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_avoir_delete', {id: this.affaire.id});
            var data = qs.stringify(avoir);

            axios.post(url, data)
                .then((response) => {
                    // On recharge l'affaire
                    this.isLoading = false;
                    this.initPopin();
                    this.$eventHub.$emit('flash-success', "Avoir supprimé");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        avoir_new() {
            //console.log("save_avoir");
            this.isLoading = true;

            var url = Routing.generate('api_avoir_new', {id: this.affaire.id});
            axios.get(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    // Nouvelle avoir
                    var avoir = response.data.avoir;
                    // On l'ajoute à l'affaire
                    this.affaire.avoirs.push(avoir);
                    // et on l'édite
                    this.edit_avoir(avoir, true);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },


        /**
         * Validation comptable des tmas
         */
        validation_tma(tma, statut = true) {
            //console.log("validation_tma", tma.id, statut);

            // Non validable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'tma') {
                return;
            }

            // Confirmation pour dévalider un tma
            if (!statut && !confirm("Annuler ?")) {
                return;
            }

            this.error = null;
            this.isLoading = true;

            var url = Routing.generate('api_tma_statut', {id: tma.id, statut: statut ? '1' : 0});
            axios.post(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Tma " + (statut ? "validée" : "invalidée"));
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Passe la tma en mode édition
         * @param tma
         * @param isEdit
         */
        edit_tma(tma, isEdit) {
            // RG_AF033: tma éditable tant qu'elle n'est pas validée
            if (tma.statut) {
                this.$eventHub.$emit('flash-error', "Impossible d'éditer une tma validée");
                return;
            }

            // Non éditable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'tma') {
                return;
            }

            tma.is_edit = isEdit;

            if (isEdit) {
                this.isEdit = 'tma';
                this.errors = [];
                // Edition, on force l'actualisation, pour afficher le formulaire
                // Je sais pas pourquoi vue ne détecte pas le changement de tma.isEdit
                this.$forceUpdate();
            }
            else {
                // Annulation, on recharge l'affaire pour réinitialiser les données éventuellement modifiées
                this.initPopin();
                this.afficherAffaire(this.affaire.id, this.tab);
            }
        },

        /**
         * Enregistre un tma
         */
        save_tma(tma) {
            //console.log("save_tma");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_tma_save', {id: this.affaire.id});
            var data = qs.stringify(tma);

            axios.post(url, data)
                .then((response) => {
                    tma.is_edit = false;
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Tma enregistrée");

                    // Si plus aucun tma en édition
                    if (!this.is_edit_tma()) {
                        this.initPopin();
                        // On recharge l'affaire
                        this.afficherAffaire(this.affaire.id, this.tab);
                    }
                    else {
                        // Edition, on force l'actualisation, pour afficher le formulaire
                        // Je sais pas pourquoi vue ne détecte pas le changement de tma.isEdit
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Retourne VRAI si au moins un tma est en édition
         * @returns {boolean}
         */
        is_edit_tma: function() {
            var is_edit = false;
            this.affaire.tmas.forEach(function(t) {
                is_edit = is_edit || t.is_edit;
            });
            return is_edit;
        },

        /**
         * Supprime un tma
         */
        delete_tma(tma) {
            //console.log("delete_tma");

            // Confirmation pour supprimer
            if (!confirm("Supprimer ?")) {
                return;
            }

            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_tma_delete', {id: this.affaire.id});
            var data = qs.stringify(tma);

            axios.post(url, data)
                .then((response) => {
                    // On recharge l'affaire
                    this.isLoading = false;
                    this.initPopin();
                    this.$eventHub.$emit('flash-success', "Tma supprimée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        tma_new() {
            //console.log("save_tma");
            this.isLoading = true;

            var url = Routing.generate('api_tma_new', {id: this.affaire.id});
            axios.get(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    // Nouvelle tma
                    var tma = response.data.tma;
                    // On l'ajoute à l'affaire
                    this.affaire.tmas.push(tma);
                    // et on l'édite
                    this.edit_tma(tma, true);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Ajout d'un commentaire
         */
        add_commentaire() {
            //console.log("add_commentaire");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_affaire_commentaire', {id: this.affaire.id});
            var data = qs.stringify({
                affaire: this.affaire.id,
                commentaire: this.commentaire
            });

            axios.post(url, data)
                .then((response) => {
                    // On recharge l'affaire
                    this.commentaire = null;
                    this.isLoading = false;
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Suppression d'un commentaire
         */
        delete_commentaire(com) {
            // Confirmation
            if (!confirm("Supprimer le commentaire ?")) {
                return;
            }
            this.isLoading = true;

            var url = Routing.generate('api_affaire_commentaire_delete', {id: this.affaire.id});
            var data = qs.stringify({
                id: com.id
            });

            axios.post(url, data)
                .then((response) => {
                    // On recharge l'affaire
                    this.commentaire = null;
                    this.isLoading = false;
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * A appeler pour scroller en bas des commentaires
         * (inutilisé)
         */
        scroll_comments() {
            this.$nextTick(() => {
                // Scroll en bas des commentaires
                var container = this.$el.querySelector(".comments ul");
                container.scrollTop = container.scrollHeight;
            });
        },



        /**
         * Validation comptable des situations
         */
        validation_situation(situation, statut = true) {
            //console.log("validation_situation", situation.id, statut);

            // Non validable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'situation') {
                return;
            }

            // Confirmation pour dévalider un situation
            if (!statut && !confirm("Annuler ?")) {
                return;
            }

            this.error = null;
            this.isLoading = true;

            var url = Routing.generate('api_situation_statut', {id: situation.id, statut: statut ? '1' : 0});
            axios.post(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Situation " + (statut ? "validée" : "invalidée"));
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Passe l'situation en mode édition
         * @param situation
         * @param isEdit
         */
        edit_situation(situation, isEdit) {
            // RG_AF033: situation éditable tant qu'elle n'est pas validée
            if (situation.statut) {
                this.$eventHub.$emit('flash-error', "Impossible d'éditer un situation validée");
                return;
            }

            // Non éditable si autre chose est déjà en édition
            if (this.isEdit && this.isEdit !== 'situation') {
                return;
            }

            situation.is_edit = isEdit;

            if (isEdit) {
                this.isEdit = 'situation';
                this.errors = [];
                // Edition, on force l'actualisation, pour afficher le formulaire
                // Je sais pas pourquoi vue ne détecte pas le changement de situation.isEdit
                this.$forceUpdate();
            }
            else {
                // Annulation, on recharge l'affaire pour réinitialiser les données éventuellement modifiées
                this.initPopin();
                this.afficherAffaire(this.affaire.id, this.tab);
            }
        },

        /**
         * Enregistre un situation
         */
        save_situation(situation) {
            //console.log("save_situation");
            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_situation_save', {id: this.affaire.id});
            var data = qs.stringify(situation);

            axios.post(url, data)
                .then((response) => {
                    situation.is_edit = false;
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Situation enregistrée");

                    // Si plus aucun situation en édition
                    if (!this.is_edit_situation()) {
                        this.initPopin();
                        // On recharge l'affaire
                        this.afficherAffaire(this.affaire.id, this.tab);
                    }
                    else {
                        // Edition, on force l'actualisation, pour afficher le formulaire
                        // Je sais pas pourquoi vue ne détecte pas le changement de situation.isEdit
                        this.$forceUpdate();
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Retourne VRAI si au moins un situation est en édition
         * @returns {boolean}
         */
        is_edit_situation: function() {
            var is_edit = false;
            this.affaire.situations.forEach(function(s) {
                is_edit = is_edit || s.is_edit;
            });
            return is_edit;
        },

        /**
         * Supprime un situation
         */
        delete_situation(situation) {
            //console.log("delete_situation");

            // Confirmation pour supprimer
            if (!confirm("Supprimer ?")) {
                return;
            }

            this.errors = [];
            this.isLoading = true;

            var url = Routing.generate('api_situation_delete', {id: this.affaire.id});
            var data = qs.stringify(situation);

            axios.post(url, data)
                .then((response) => {
                    // On recharge l'affaire
                    this.initPopin();
                    this.isLoading = false;
                    this.$eventHub.$emit('flash-success', "Situation supprimée");
                    this.afficherAffaire(this.affaire.id, this.tab);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        situation_new() {
            //console.log("save_situation");
            this.isLoading = true;

            var url = Routing.generate('api_situation_new', {id: this.affaire.id});
            axios.get(url)
                .then((response) => {
                    //console.log("then OK");
                    //console.log(response);
                    this.isLoading = false;
                    // Nouvelle situation
                    var situation = response.data.situation;
                    // On l'ajoute à l'affaire
                    this.affaire.situations.push(situation);
                    // et on l'édite
                    this.edit_situation(situation, true);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Affiche le formulaire d'édition d'un client
         */
        edit_client(client_id) {
            this.$eventHub.$emit('client_edit', client_id, 'reload');
        },

        /**
         * Affichage popup pour l'impression
         * @param route
         */
        popup(route) {
            var url = Routing.generate(route, {id: this.affaire.id});
            window.open(url,'MyWindow','width=830,height=1000');
        },

        /**
         * Page des logs de l'affaire
         * @param affaire
         */
        show_logs(affaire) {
            var url = Routing.generate('log', {co: this.affaire.numero_co});
            //window.open(url);
            location.href = url;
        },

    }
};
</script>

