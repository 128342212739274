var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flashMessage" },
    _vm._l(_vm.messages, function (message) {
      return message.show
        ? _c("div", { staticClass: "message", class: message.type }, [
            _c("p", { domProps: { innerHTML: _vm._s(message.message) } }),
          ])
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }