<script>
/**
 * Building an Autocomplete Component with Vue.js
 * https://alligator.io/vuejs/vue-autocomplete-component/
 */

const axios = require('axios');
const qs = require('qs');

import AutocompleteProdevisCo from './autocomplete_prodevis_co.vue';

const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

export default {
    name: 'affaire_creation',
    components: {
        'autocomplete_prodevis_co': AutocompleteProdevisCo,
    },
    props: ['type'],
    data() {
        return {

            loading: true,
            error: null,

            // évènement de callback après la création de l'affaire
            callback: null,

            // Import de Prodevis
            select_to_import: false,
            affaires_prodevis : [],
            co_prodevis: null,

            // Formulaire de création
            affaire: null,
            client: null,
            chefs_projet: [],
            agences: [],
            errors: [],

        };
    },
    created() {
        this.$eventHub.$on('affaire_import', this.list_prodevis);
        this.$eventHub.$on('affaire_creation', this.add_affaire);
    },
    beforeDestroy() {
        this.$eventHub.$off('affaire_creation');
        document.removeEventListener('keydown', this.doKeyEvt);
    },
    mounted() {
        this.fetch_data();
    },
    destroyed() {
    },
    watch: {
        'affaire.chef_projet': function (chef) {
            if (chef && chef.agence) {
                this.affaire.agence = chef.agence;
            }
        },
    },
    methods: {

        fetch_data() {
            // Si chargement déjà en cours, on arrête
            this.loading = true;

            this.error = null;
            this.errors = [];
            this.chefs_projet = [];
            this.agences = [];

            var url = Routing.generate('api_list_chefs_projet', {type:this.type});
            axios.get(url)
                .then((response) => {
                    this.loading = false;
                    this.chefs_projet = response.data.chefs_projet;
                    this.agences = response.data.agences;
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Gestion des retour d'erreur de l'API
         */
        error_api(error) {
            this.loading = false;
            console.log(error, error.response);
            if (error.response) {
                this.errors = error.response.data ?? [];
            }
            else {
                this.$eventHub.$emit('flash-error', error);
            }
        },

        /**
         * Gestion des raccourcis clavier
         */
        doKeyEvt(e) {
            //console.log(e.key);
            switch (e.key) {
                case "Enter":
                    break;
                case "Escape":
                    this.cancel_affaire();
                    break;
            }
        },

        /**
         * Appelé lors de l'évènement de sélection d'une affaire Prodevis dans la liste
         */
        set_prodevis_co(co) {
            this.co_prodevis = co;
        },

        /**
         * Nouvelle affaire
         * Affichage popin de sélection d'une affaire dans Prodevis pour l'import
         * RG_AF002
         */
        list_prodevis() {
            // console.log("list_prodevis()");
            this.co_prodevis = null;

            this.loading = true;
            this.errors = [];

            var url = Routing.generate('api_affaire_list_prodevis');
            axios.get(url)
                .then((response) => {
                    this.loading = false;
                    //console.log(response);
                    this.affaires_prodevis = response.data;
                    this.select_to_import = true;
                    /** Gestion des raccourcis clavier */
                    document.addEventListener('keydown', this.doKeyEvt);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Importe les données de l'affaire de Prodevis
         * pour préremplir le formulaire de création d'une affaire.
         * Et affiche le formulaire de création
         * RG_AF003
         */
        import_prodevis(co_prodevis) {
            console.log("import_prodevis("+co_prodevis+")");
            this.select_to_import = true;
            this.loading = true;
            this.errors = [];
            var url = Routing.generate(
                'api_affaire_import_prodevis', {
                    co: co_prodevis,
                    type: this.type,
                });
            axios.get(url)
                .then((response) => {
                    this.loading = false;
                    //console.log(response);
                    this.affaire = response.data.affaire;
                    this.client = response.data.client;
                    this.select_to_import = false;
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Affiche le formulaire vierge de création d'une nouvelle affaire
         */
        add_affaire(callback = null) {
            this.select_to_import = false;
            this.co_prodevis = null; // Pas d'import Prodevis
            this.loading = true;
            this.callback = callback; // Evènement de callback
            this.errors = [];
            var url = Routing.generate('api_affaire_new', {type: this.type});
            axios.get(url)
                .then((response) => {
                    //console.log(response);
                    this.affaire = response.data.affaire;
                    this.client = response.data.client;
                    /** Gestion des raccourcis clavier */
                    document.addEventListener('keydown', this.doKeyEvt);
                    this.loading = false;
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Sauvegarde la nouvelle affaire
         * (méthode appelée par l'enregistrement du client)
         */
        save_affaire() {
            console.log("save_affaire()");
            this.errors = [];
            this.loading = true;

            // Sauvegarde affaire (création)
            var url = Routing.generate('api_affaire_create');
            var data = qs.stringify(this.affaire, {filter: function (prefix, value) {
                    switch (prefix) {
                        case 'chef_projet':
                            return value ? value.id : null;
                        case 'client':
                            return value ? value.id : null;
                        case '': // Pour le préfix racine
                        case 'numero_co':
                        case 'id_prodevis':
                        default: // tout le reste
                            return value;
                    }
                }});
            axios.post(url, data)
                .then((response) => {
                    this.affaire = response.data.affaire;

                    //this.$eventHub.$emit(
                    //    'flash-success',
                    //    "Création l'affaire <strong>"+this.affaire.numero_co+"</strong>"
                    //);

                    // Si import de Prodevis,
                    if (this.co_prodevis) {
                        // On importe maintenant les lignes d'achat
                        this.import_lignes_achat();
                    }
                    
                    // Sinon on recharge les données de la page
                    else {
                        var id = this.affaire.id;
                        // on ferme le formulaire de création
                        this.affaire = null;
                        // on n'est pas en chargement
                        this.loading = false;
                        // Et on affiche l'affaire importée
                        this.afficher_affaire(id);
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Fermeture du formulaire de création/import d'une affaire
         */
        cancel_affaire() {
            this.errors = [];
            this.affaire = null;
            this.select_to_import = false;
            // On supprime les raccourcis clavier
            document.removeEventListener('keydown', this.doKeyEvt);
        },

        /**
         * RG_AF005
         * Sauvegarde le client
         *
         * RG_AF006
         * Si client existant, popin de validation changement infos client
         *
         * Une fois le client enregistré, enregistrement de l'affaire
         */
        save_client() {
            this.errors = [];
            this.loading = true;

            var url = Routing.generate('api_client_save');
            var data = qs.stringify(this.client, {filter: function (prefix, value) {
                    switch (prefix) {
                        case 'referent':
                            return value ? value.id : null;
                        case '': // Pour le préfix racine
                        default: // on enregistre tout le reste
                            return value;
                    }
                }});
            axios.post(url, data)
                .then((response) => {
                    this.affaire.client = response.data.client;
                    //this.$eventHub.$emit(
                    //    'flash-success',
                    //    "Enregistrement du client <strong>"+this.affaire.client.contact.nom_complet+"</strong>"
                    //);

                    // TODO : RG_AF006 popin validation changement infos client
                    this.save_affaire();

                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Import des lignes d'achat
         * Après l'import d'une nouvelle affaire
         */
        import_lignes_achat() {
            console.log("import_lignes_achat(" + this.affaire.numero_co + ":" + this.affaire.id_prodevis + ")");
            if (null == this.affaire.id_prodevis)
                return;

            this.errors = [];

            var url = Routing.generate('api_lignes_achat_import_prodevis', {idProdevis: this.affaire.id_prodevis});
            axios.post(url)
                .then((response) => {
                    this.affaire = response.data.affaire;

                    if (this.affaire.lignes_achat.length === 0) {
                        this.$eventHub.$emit(
                            'flash-warning',
                            "Aucune ligne d'achat trouvée dans Prodevis"
                        );
                    }
                    else {
                        //this.$eventHub.$emit('flash-success', "Import des lignes d'achat");
                    }

                    // Affichage popin affaire importée
                    var id = this.affaire.id;
                    // on ferme le formulaire de création
                    this.affaire = null;
                    // on n'est pas en chargement
                    this.loading = false;
                    // Rechargement des données de la page
                    //this.fetch_data();
                    // Et on affiche l'affaire importée
                    this.afficher_affaire(id);
                    // On supprime les raccourcis clavier
                    document.removeEventListener('keydown', this.doKeyEvt);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Action d'affichage d'une affaire
         * Appelle la popin d'affaire
         */
        afficher_affaire(id) {
            console.log("afficher_affaire("+id+")" + this.callback);
            if (this.callback) {
                this.$eventHub.$emit(this.callback, id);
            }
            else {
                this.$eventHub.$emit('affaire', id);
            }
        },

    }
};
</script>

