<script>
export default {
    name: 'autocomplete',

    props: {
        error: '',
        start: '',
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data() {
        return {
            isOpen: false,
            results: [],
            search: '',
            arrowCounter: 0,
        };
    },

    methods: {
        onChange() {
            this.filterResults();
            this.isOpen = true;
            this.$emit('input', this.search);
        },

        filterResults() {
            // first uncapitalize all the things
            this.results = this.items.filter((item) => {
                return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
        setResult(result) {
            this.search = result;
            this.isOpen = false;
            this.$emit('input', result);
        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter -1;
            }
        },
        onEnter() {
            this.setResult(this.results[this.arrowCounter]);
            this.isOpen = false;
            this.arrowCounter = -1;

        },
        /*
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        }
        */
    },
    watch: {
        items: function (val, oldValue) {
            // actually compare them
            if (val.length !== oldValue.length) {
                this.results = val;
            }
        },
    },
    mounted() {
        //document.addEventListener('click', this.handleClickOutside)
        this.search = this.start;
    },
    destroyed() {
        //document.removeEventListener('click', this.handleClickOutside)
    }
};
</script>