/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

console.log("I'M BATMAN!");

// Tailwind
require('../../integration/source/css/main.css');


import Vue from 'vue';

// Composants globaux
import Loader from './components/loader.vue';
import FlashMessages from './components/flashMessages.vue';
import AffairePopin from './components/affaire_popin.vue';
import AffaireRecherche from './components/affaire_recherche.vue';
import AffaireCreation from './components/affaire_creation.vue';
import ClientRecherche from './components/client_recherche.vue';
import ClientEdit from './components/client_edit.vue';
import PlanningIntervention from './components/planning-intervention.vue';

Vue.component('loader', Loader);
Vue.component('flash-messages', FlashMessages);
Vue.component('affaire-popin', AffairePopin);
Vue.component('affaire-recherche', AffaireRecherche);
Vue.component('affaire-creation', AffaireCreation);
Vue.component('client-recherche', ClientRecherche);
Vue.component('client-edit', ClientEdit);
Vue.component('planning-intervention', PlanningIntervention);


// Bus pour les évènements
Vue.prototype.$eventHub = new Vue(); // Global event bus

/**
 * Filtres d'affichage
 */

// DATE

const moment = require('moment');
moment.locale('fr');
Vue.filter('date', function(value, format, placeholder) {
    if (value) {
        return moment(String(value)).format(format);
    }
    else {
        return placeholder || null;
    }
});

// NL2BR
// https://stackoverflow.com/a/7467863/11105345

Vue.filter('nl2br', function(str) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
});

// EURO

Vue.filter('euro', function(value, decimal) {
    let v = parseFloat(value);
    decimal = (undefined === decimal ? 2 : decimal);
    if (!isNaN(v)) {
        return v.toLocaleString("fr-FR", {style: "currency", currency: "EUR", minimumFractionDigits: decimal, maximumFractionDigits: decimal});
    }
    return null;
});

// POURCENT

Vue.filter('pourcent', function(value, decimal) {
    let v = parseFloat(value);
    decimal = decimal || 0;
    if (!isNaN(v)) {
        return v.toLocaleString("fr-FR", {style: "percent", minimumFractionDigits: decimal, maximumFractionDigits: decimal});
    }
    return null;
});

// DECIMAL, sans unité

Vue.filter('decimal', function(value, decimal) {
    let v = parseFloat(value);
    decimal = decimal || 0;
    if (!isNaN(v)) {
        return v.toLocaleString("fr-FR", {style: "decimal", minimumFractionDigits: decimal, maximumFractionDigits: decimal});
    }
    return null;
});

// Formattage numéro de téléphone

Vue.filter('tel', function(value) {
    if (value === null) {
        return null;
    }
    if (value.toString().indexOf('+') >= 0) {
        // +33 xx xx xx xx
        return value.toString().substr(0, 3)
            + " "
            + value.toString().substr(3, 1)
            + " "
            + value.toString().substr(4).replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1 ");
    }
    else {
        // 0x xx xx xx xx
        return value.toString().replace(/(\d)(?=(\d{2})+(?!\d))/g, "$1 ");
    }
});


// PWA
// https://www.pwabuilder.com
// This is the "Offline page" service worker

if ("serviceWorker" in navigator) {
    if (navigator.serviceWorker.controller) {
        console.log("[PWA Builder] active service worker found, no need to register");
    } else {
        // Register the service worker
        navigator.serviceWorker
            .register("/pwabuilder-sw.js", {
                scope: "/"
            })
            .then(function (reg) {
                console.log("[PWA Builder] Service worker has been registered for scope: " + reg.scope);
            });
    }

//    window.addEventListener('beforeinstallprompt', (e) => {
//        // Prevent Chrome 67 and earlier from automatically showing the prompt
//        e.preventDefault();
//        // Stash the event so it can be triggered later.
//        deferredPrompt = e;
//    });
}
// <a href="#" onclick="install()">Installer la PWA</a>
//let deferredPrompt = null;
//async function install() {
//    if (deferredPrompt) {
//        deferredPrompt.prompt();
//        console.log(deferredPrompt)
//        deferredPrompt.userChoice.then(function(choiceResult){
//            if (choiceResult.outcome === 'accepted') {
//                console.log('Your PWA has been installed');
//            } else {
//                console.log('User chose to not install your PWA');
//            }
//            deferredPrompt = null;
//        });
//    }
//}


