<script>
    export default {
        name: 'planning-intervention',
        // intervention
        // rensigné avec 'v-model'
        props: [
            'creneau',
            'afficher_warnings',
            'filtres_semaine',
            'surbrillance',
        ],
        data() {
            return {};
        },
        methods: {
            
            afficher_warning(creneau, niveau, warning) {
                // Cas particulier des bl/stock à ne pas afficher au delà de 2 semaines
                if (warning === 'bl_manquant' || warning === 'stock_manquant') {
                    return this.afficher_warnings['bl']
                        && this.afficher_warnings[warning]
                        && creneau.affaire.warning[niveau+'_'+warning];
                }
                return this.afficher_warnings[warning]
                    && creneau.affaire.warning[niveau+'_'+warning];
            },
        },
    };
</script>

