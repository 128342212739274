<script>
const axios = require('axios');
const qs = require('qs');

const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

export default {
    name: 'client_edit',
    props: [],
    data() {
        return {

            loading: false,
            error: null,

            // évènement de callback après la création du client
            callback: null,
            callback_params: null,

            // Formulaire de création
            client: null,
            referents: [],
            errors: [],

        };
    },
    created() {
        this.$eventHub.$on('client_edit', this.edit_client);
        this.$eventHub.$on('client_add', this.add_client);
    },
    beforeDestroy() {
        this.$eventHub.$off('client_edit');
        this.$eventHub.$off('client_add');
        document.removeEventListener('keydown', this.doKeyEvt);
    },
    mounted() {
        this.fetch_data();
    },
    destroyed() {
    },
    methods: {

        fetch_data() {
            // Si chargement déjà en cours, on arrête
            if (this.loading) return;
            this.loading = true;

            this.error = null;
            this.errors = [];
            this.referents = [];

            var url = Routing.generate('api_list_chefs_projet');
            axios.get(url)
                .then((response) => {
                    this.loading = false;
                    this.referents = response.data.chefs_projet;
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Gestion des retour d'erreur de l'API
         */
        error_api(error) {
            this.loading = false;
            //console.log(error, error.response);
            if (error.response) {
                this.errors = error.response.data ?? [];
            }
            else {
                this.$eventHub.$emit('flash-error', error);
            }
        },

        /**
         * Gestion des raccourcis clavier
         */
        doKeyEvt(e) {
            //console.log(e.key);
            switch (e.key) {
                case "Enter":
                    break;
                case "Escape":
                    this.cancel_client();
                    break;
            }
        },

        /**
         * Affiche le formulaire vierge de création d'une nouvelle client
         */
        add_client(callback = null) {
            this.loading = true;
            this.callback = callback; // Evènement de callback
            this.errors = [];

            var url = Routing.generate('api_client_new');
            axios.get(url)
                .then((response) => {
                    this.loading = false;
                    //console.log(response);
                    this.client = response.data.client;
                    /** Gestion des raccourcis clavier */
                    document.addEventListener('keydown', this.doKeyEvt);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Affiche le formulaire d'édition d'un client
         */
        edit_client(client_id, callback = null) {
            this.loading = true;
            this.callback = callback; // Evènement de callback
            this.errors = [];

            var url = Routing.generate('api_client_get', {id: client_id});
            axios.get(url)
                .then((response) => {
                    this.loading = false;
                    //console.log(response);
                    // Chargement des données du client
                    this.client = response.data.client;
                    // Gestion des raccourcis clavier
                    document.addEventListener('keydown', this.doKeyEvt);
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Enregistre le formulaire d'édition
         */
        save_client() {
            this.errors = [];

            var url = Routing.generate('api_client_save');
            var data = qs.stringify(this.client, {filter: function(prefix, value) {
                    switch (prefix) {
                        case 'referent':
                            if (value) return value.id;
                            break;
                        case '': // Pour le préfix racine
                        default:
                            return value;
                    }
                }});

            axios.post(url, data)
                .then((response) => {
                    //console.log("then OK");
                    this.loading = false;

                    this.$eventHub.$emit(
                        'flash-success',
                        "Enregistrement du client <strong>"+this.client.contact.nom_complet+"</strong>"
                    );

                    this.client = null;

                    document.removeEventListener('keydown', this.doKeyEvt);

                    if (this.callback) {
                        this.$eventHub.$emit(this.callback, this.callback_params);
                    }
                })
                .catch((error) => {
                    this.error_api(error);
                })
            ;
        },

        /**
         * Fermeture du formulaire de création/import d'une client
         */
        cancel_client() {
            this.errors = [];
            this.client = null;
            this.select_to_import = false;
            // On supprime les raccourcis clavier
            document.removeEventListener('keydown', this.doKeyEvt);
        },

    }
};
</script>

