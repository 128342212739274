<script>
/**
 * Building an Autocomplete Component with Vue.js
 * https://alligator.io/vuejs/vue-autocomplete-component/
 */

const axios = require('axios');
const qs = require('qs');

const CancelToken = axios.CancelToken;

const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

export default {
    name: 'affaire_recherche',
    props: {
        // Affaire sélectionnée par défaut
        // pour les form autocomplete
        // rensigné avec 'v-model'
        value: {default: null},
        // Pour ouvrir l'affaire au clic
        // Sinon envoie juste le résultat au parent
        open_affaire: {
            type: Boolean,
            default: true,
        },
        // Libellé de l'input
        libelle: {
            default: null,
        },
        // Pour les options de recherche
        form: {
            type: Object,
            default: () => {return {};}
            },
    },
    data() {
        return {
            search: '',
            placeholder: '',

            // Pour annuler les requêtes en cours
            // https://stackoverflow.com/questions/38329209/how-to-cancel-abort-ajax-request-in-axios
            cancel: null,

            isOpen: false,
            isLoading: false,
            timer: null,
            arrowCounter: -1,

            affaire: null,
            count: 0,
            affaires: [],
        };
    },
    computed: {
        search_url: function() {
            return Routing.generate('affaires_list')
                + '?search=' + this.search
                + '&statut=all';
        },
    },
    created() {
        this.$eventHub.$on('affaire_recherche_clear_placeholder', () => {this.placeholder = ''});
    },
    beforeDestroy() {
        this.$eventHub.$off('affaire_recherche_clear_placeholder');
    },
    mounted() {
        document.addEventListener('click', this.onClickOutside);
        if (this.value && this.value.id) {
            this.placeholder =
                this.value.numero_co
                + " | " +
                this.value.client.contact.nom_complet
                + " | " +
                this.value.adresse_chantier.ville_dpt
            ;
        }
    },
    destroyed() {
        document.removeEventListener('click', this.onClickOutside);
    },
    methods: {
        onChange() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.searchResult, 300);
        },
        onEnter() {
            if (this.arrowCounter < 0) {
                if (this.open_affaire) {
                    window.location.href = this.search_url;
                }
            }
            else {
                this.setResult(this.arrowCounter);
            }
        },
        onArrowDown() {
            if (this.arrowCounter < this.affaires.length - 1) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEscape() {
            this.search = "";
            this.isOpen = false;
            this.arrowCounter = -1;
        },
        onClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                this.search = "";
                this.isOpen = false;
                this.arrowCounter = -1;
            }
        },
        searchResult() {
            // Annulation de la requête en cours
            if (this.cancel !== null && this.cancel !== undefined) {
                //console.log("cancel");
                this.cancel();
            }

            if (this.search.length > 3) {
                this.isOpen = true;
                this.isLoading = true;
                this.affaires = [];

                var form_data = this.form;
                form_data.search = this.search;
                var url = Routing.generate('api_affaire_search');
                var that = this;
                var cancelToken = {cancelToken: new CancelToken(function executor(c) {that.cancel = c;})};

                axios.post(url, qs.stringify(form_data), cancelToken)
                    .then((response) => {
                        this.isLoading = false;
                        this.affaires = response.data.affaires;
                        this.count = response.data.count;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    })
                ;
            }
            else {
                this.isLoading = false;
                this.affaires = [];
                this.isOpen = false;
            }
        },
        setResult(index) {
            this.isOpen = false;
            this.search = "";
            if (this.open_affaire) {
                this.$eventHub.$emit('affaire', this.affaires[index].id);
            }
            else {
                var affaire = this.affaires[index];
                this.$emit('input', this.affaires[index]);
                this.placeholder =
                    affaire.numero_co
                    + " | " +
                    affaire.client.contact.nom_complet
                    + " | " +
                    affaire.adresse_chantier.ville_dpt
                ;
            }
        },
    }
};
</script>

