<script>
export default {
    name: 'flash-messages',

    props: {},

    data() {
        return {
            messages: [],
        };
    },
    created() {
        this.$eventHub.$on('flash-error',   this.http_error);
        this.$eventHub.$on('flash-warning', this.warning);
        this.$eventHub.$on('flash-info',    this.info);
        this.$eventHub.$on('flash-success', this.success);
    },
    beforeDestroy() {
        this.$eventHub.$off('flash');
    },
    methods: {
        error(message)   { this.message({ message: message, type: 'error',   auto: true, show: true }); },
        warning(message) { this.message({ message: message, type: 'warning', auto: true, show: true }); },
        info (message)   { this.message({ message: message, type: 'info',    auto: true, show: true }); },
        success (message){ this.message({ message: message, type: 'success', auto: true, show: true }); },

        http_error(error) {
            var link = '';
            var message = error.toString();

            if (error.response) {

                // On affiche le token d'erreur symfony
                /*
                if (error.response.headers && error.response.headers['x-debug-token']) {
                    link = " <a href=" + error.response.headers['x-debug-token-link'] + ">" + error.response.headers['x-debug-token'] + "</a>";
                }
                */

                // Si message trop long, on affiche l'erreur générique
                if (error.response.data && error.response.data.detail.length < 100) {
                    message = '[' + error.response.status + '] ' + error.response.data.detail;
                }
            }
            this.error(message + link);
        },

        message(message) {
            this.messages.push(message);
            if (message.auto) {
                setTimeout(function() {
                    //message.type += ' close';
                    // setTimeout(function() {
                        message.show = false;
                    // }, 2000);
                }, 5000);
            }
        }
    },

};
</script>

<template>
    <div class="flashMessage">

        <div class="message" v-for="message in messages" :class="message.type" v-if="message.show">
            <p v-html="message.message"></p>
        </div>

    </div>
</template>
