<script>
    const axios = require('axios');
    const qs = require('qs');

    export default {
        name: 'autocomplete_prodevis_co',
        data() {
            return {
                search: null,
                placeholder: '',
                isOpen: false,
                isLoading: false,
                affaire: {co: ''},
                affaires: [],
                timer: null,
                arrowCounter: -1,
            };
        },
        methods: {
            onChange() {
                clearTimeout(this.timer);
                this.timer = setTimeout(this.searchResult, 500);
                this.affaire = {co: ''};
                this.arrowCounter = -1;
                this.$emit('input', null);
            },
            onEnter() {
                if (this.arrowCounter < 0) {
                    // Aucune affaire sélectionnée
                }
                else {
                    this.setResult(this.arrowCounter);
                }
            },
            onArrowDown() {
                if (this.arrowCounter < this.affaires.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                    this.$emit('input', null);
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                    this.$emit('input', null);
                }
            },
            onEscape() {
                this.search = "";
                this.isOpen = false;
                this.arrowCounter = -1;
                this.$emit('input', null);
            },
            /*
            onClickOutside(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.search = "";
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            },
            */

            searchResult() {
                if (this.search.length > 4) {
                    //console.log("search");
                    this.isOpen = true;
                    this.isLoading = true;

                    var url = "/api/prodevis/list";
                    axios.post(url, qs.stringify({"co": this.search}))
                        .then((response) => {
                            this.isLoading = false;
                            //console.log(response);
                            this.affaires = response.data;
                        })
                        .catch((error) => {
                            this.error_api(error);
                        })
                    ;
                }
            },
            setResult(index) {
                this.isOpen = false;
                this.affaire = this.affaires[index];
                this.search = this.affaires[index].co;
                this.$emit('input', this.affaires[index].co);

                var affaire = this.affaires[index];
                this.search = null;
                this.placeholder =
                    affaire.co
                    + " | " +
                    affaire.nom_client
                    + " | " +
                    affaire.ville_client
                ;
            },

            /**
             * Gestion des retour d'erreur de l'API
             */
            error_api(error) {
                this.isLoading = false;
                console.log(error, error.response);
                this.$eventHub.$emit('flash-error', '[' + error.response.status + '] ' + error.response.data);
            },
        }
    };
</script>
