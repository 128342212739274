<script>
export default {
    name: 'loader',

    props: {},

    data() {
        return {
            isLoading: false,
        };
    },
    created() {
        this.$eventHub.$on('loading', this.loading);
    },
    beforeDestroy() {
        this.$eventHub.$off('loading');
    },
    methods: {
        loading(loading) {
            this.isLoading = loading;
        }
    },

};
</script>

<template>
    <div class="loader" v-show="isLoading">
        <div>
            <svg viewBox="25 25 50 50">
                <circle cx="50" cy="50" r="20"></circle>
            </svg>
        </div>
    </div>
</template>
